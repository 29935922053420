import React from 'react'
import {
  Address,
  Breadcrumbs,
  Contacts,
  Hero,
  Page,
} from '@components'

const meta = {
  title: 'Kontakt | Protupožarna zaštita - FSB d.o.o.',
  description: 'Za sva dodatna pitanja stojimo vam na raspolaganju. Obratite nam se sa povjerenjem',
}

const Homepage = () => (
  <Page
    isHomepage
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/kontakt/', name: 'Kontakt'},
      ]}
    />
    <Hero
      title="Kontakt"
      subtitle="Obratite nam se sa povjerenjem"
      description="Za sva dodatna pitanja stojimo vam na raspolaganju."
    />
    <Contacts />
    <Address />
  </Page>
)

export default Homepage